<template>
    <div class="add-broker-wrapper grid gap-15">

        <Input name="Name" v-model="item.name" :error="errors.name" placeholder="Enter broker name"/>
        <Input name="MC #" v-model="item.mc" :error="errors.mc" placeholder="Enter broker MC #"/>

    </div>
</template>

<script>
    export default {
        props: ['modal', 'item', 'errors']
    }
</script>

<style lang="scss" scoped>
.add-broker-wrapper {
    padding: 20px;
}
</style>