<template>
    <div class="modal-wrapper grid gap-15">
        
        <InputDropDownList name="Dispatcher" :error="errors.user" v-model="item.user" placeholder="Select dispatcher" selectAttribute="_id" :showAttribute="(user) => { return user.name + ' - ' + user.lastname }" :options="dispatchers"/>

    </div>
</template>

<script>

    export default {
        props: ['modal', 'loadId'],
        data() {
            return {
                item: {},
                errors: {},
                dispatchers: []
            }
        },
        methods: {
            async updateLoadDispatcher() {
                this.modal.processing(true);
                this.errors = {};
                await this.ajax('UpdateLoad-LoadDispatcher', {
                    url: `/dispatch/loads/${this.loadId}/user`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.modal.close();
                });
                this.modal.processing(false);
            },
            getListOfDispatchers() {
                this.ajax('GetListOfDispatchers', {
                    url: '/users/permissions?name=Loads',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.dispatchers = body;
                });
            },
        },
        mounted() {
            this.getListOfDispatchers();
            this.modal.onConfirm(() => {
                this.updateLoadDispatcher();
            });
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}
</style>