<template>
    <div class="modal-wrapper grid gap-15">
       
        <Input name="Rate" v-model="item.rate" :error="errors.rate" placeholder="Enter load rate"/>
        <div class="grid cols-2 gap-15">
            <Input name="Weight" v-model="item.weight" :error="errors.weight" placeholder="Enter load weight"/>
            <Input name="Length" v-model="item.length" :error="errors.length" placeholder="Enter load length"/>
        </div>
        <InputFileStorageUploader name="Rate confirmation" v-model="item.rateConfirmation" :selected="item.rateConfirmationFile"  :error="errors.rateConfirmation"/>
        
    </div>
</template>

<script>
    export default {
        props: ['modal', 'loadId'],
        data() {
            return {
                item: {},
                errors: {}
            }
        },
        methods: {
            async getLoadDetails() {
                this.modal.processing(true);
                await this.ajax('GetLoad-LoadDetails', {
                    url: `/dispatch/loads/${this.loadId}/load-details`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || TEXT_ERRORS['SOMETHING_WRONG'])
                        return;
                    }
                    this.item = body;
                });
                this.modal.processing(false);
            },
            async updateLoadDetails() {
                this.modal.processing(true);
                this.errors = {};
                await this.ajax('UpdateLoad-LoadDetails', {
                    url: `/dispatch/loads/${this.loadId}/load-details`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.modal.close();
                });
                this.modal.processing(false);
            }
        },
        mounted() {
            this.getLoadDetails();
            this.modal.onConfirm(() => {
                this.updateLoadDetails();
            });
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}
</style>