<template>
    <div class="modal-wrapper grid gap-15">
        
        <InputDropDownList :error="errors.carrier" name="Carrier" v-model="item.carrier" placeholder="Select carrier" selectAttribute="_id" showAttribute="companyName" :options="carriers"/>
        <div class="grid cols-2 gap-15" v-if="item.carrier">
            <InputDropDownList :error="errors.carrierChargeType" name="Type" position="center" v-model="item.carrierChargeType" placeholder="Select type" selectAttribute="_id" showAttribute="name" :options="chargeTypes"/>
            <InputDropDownList :error="errors.driver" name="Driver" position="center" v-model="item.driver" placeholder="Select driver" selectAttribute="_id" :showAttribute="(driver) => { return driver.name + ' ' + driver.lastname }" :options="drivers"/>
        </div>

        <CitySearch v-model="item.emptyCity" name="Empty City" placeholder="Enter empty city"/>

    </div>
</template>

<script>
import CitySearch from './CitySearch.vue';

    export default {
        components: {
            CitySearch
        },
        props: ['modal', 'loadId'],
        data() {
            return {
                item: {},
                errors: {},
                carriers: [],
                chargeTypes: [],
                drivers: [],
            }
        },
        watch: {
            "item.carrier"(newVal, oldVal) {
                this.getCarrierChargeTypes();
                this.getCarrierDrivers();
                if(oldVal) {
                    this.item.driver = null;
                    this.item.carrierChargeType = null;
                }
            }
        },
        methods: {
            getListOfCarriers() {
                this.ajax('UpdateLoad-GetCarriers', {
                    url: '/dispatch/carriers/all',
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.carriers = body;
                });
            },
            getCarrierChargeTypes() {
                this.chargeTypes = [];
                if(!this.item.carrier) return;
                this.ajax('UpdateLoad-GetCarrierChargeTypes', {
                    url: `/dispatch/carriers/chargeTypes/${this.item.carrier}`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.chargeTypes = body;
                });
            },
            getCarrierDrivers() {
                this.drivers = [];
                if(!this.item.carrier) return;
                this.ajax('UpdateLoad-GetCarrierDrivers', {
                    url: `/dispatch/carrier/drivers/${this.item.carrier}/all`,
                    method: 'GET',
                }, (err, body) => {
                    if(!err) this.drivers = body;
                });
            },
            async getLoadDetails() {
                this.modal.processing(true);
                await this.ajax('GetLoad-CarrierDetails', {
                    url: `/dispatch/loads/${this.loadId}/carrier-details`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG'])
                        return;
                    }
                    this.item = body;
                });
                this.modal.processing(false);
            },
            async updateLoadDetails() {
                this.modal.processing(true);
                this.errors = {};
                await this.ajax('UpdateLoad-CarrierDetails', {
                    url: `/dispatch/loads/${this.loadId}/carrier-details`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.modal.close();
                });
                this.modal.processing(false);
            }
        },
        mounted() {
            this.getLoadDetails();
            this.getListOfCarriers();
            this.modal.onConfirm(() => {
                this.updateLoadDetails();
            });
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}
</style>