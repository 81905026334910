import ViewLoadModalView from '../components/Dispatch/Loads/ViewLoad'
import EditLoadDetailsView from '../components/Dispatch/Loads/EditLoadDetails'

export default {
    methods: {
        loads_view(loadId) {

            let modal = this.$ShowModal({
                title: 'View Load',
                component: ViewLoadModalView,
                confirm: 'Close',
                hideCancelButton: true,
                props: {
                    loadId: loadId
                },
                width: '900px'
            });

            modal.onConfirm(() => {
                modal.close();
            });
        }
    }
}