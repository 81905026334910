import AddBrokerView from '../components/Dispatch/Brokers/AddBroker'

export default {
    data() {
        return {
            _AddBroker: {
                item: {},
                errors: {}
            }
        }
    },
    methods: {
        broker_clearValues() {
            this._AddBroker = { item: {}, errors: {} };
        },
        brokers_add(callback = null) {

            this.broker_clearValues();

            let modal = this.$ShowModal({
                title: 'Add New Broker',
                component: AddBrokerView,
                props: {
                    item: this._AddBroker.item,
                    errors: this._AddBroker.errors
                }
            });

            modal.onConfirm(async (props) => {
                modal.processing(true);
                modal.setProp('errors', {});
                await this.ajax('AddBroker-HandleAdd', {
                    url: '/dispatch/brokers',
                    method: 'POST',
                    data: props.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) modal.setProp('errors', body.errors);
                        else this.$ShowAlert(body.message || 'Something went wrong. Please, try again!');
                        return;
                    }
                    if(callback && typeof callback === 'function') callback(body); 
                    modal.close();
                });

                modal.processing(false);
            });
        }
    }
}