<template>
    <div class="modal-wrapper grid gap-15">
        
        <div class="grid gap-5">
            <InputDropDownList name="Broker" :error="errors.broker" @search="searchBrokers" :loading="isLoading(['AddLoad-SearchBrokers'])" dynamic-search v-model="item.broker" placeholder="Select broker" selectAttribute="_id" :showAttribute="(broker) => { return broker.name + ' - ' + broker.mc }" :options="brokers"/>
            <div>Can't find broker? <a href="" @click.prevent="addBroker">Add broker</a></div>   
        </div>
        <Input name="Pro #" v-model="item.brokerLoadNumber" :error="errors.brokerLoadNumber" placeholder="Enter load number" description="Pro # provided by the broker. It helps locate loads faster."/>
        <Input name="Contact name" v-model="item.brokerContactName" :error="errors.brokerContactName" placeholder="Enter contact name"/>
        <div class="grid cols-2 gap-15">
            <Input name="Phone" v-model="item.brokerContactPhone" :error="errors.brokerContactPhone" placeholder="Enter phone"/>
            <Input name="Email address" v-model="item.brokerContactEmail" :error="errors.brokerContactEmail" placeholder="Enter email address"/>
        </div>

    </div>
</template>

<script>
import BrokersJS from '../../../mixins/Brokers'

    export default {
        mixins: [BrokersJS],
        props: ['modal', 'loadId'],
        data() {
            return {
                item: {},
                errors: {},
                brokers: []
            }
        },
        watch: {
            "item.carrier"(newVal, oldVal) {
                this.getCarrierChargeTypes();
                this.getCarrierDrivers();
                if(oldVal) {
                    this.item.driver = null;
                    this.item.carrierChargeType = null;
                }
            }
        },
        methods: {
            addBroker() {
                this.brokers_add((broker) => {
                    if(broker._id) {
                        this.item.broker = broker._id;
                        this.brokers.unshift(broker);
                    }
                });
            },
            searchBrokers(search) {
                this.ajax('AddLoad-SearchBrokers', {
                    url: '/dispatch/brokers/search',
                    method: 'POST',
                    data: {
                        search,
                        forceShow: this.item.broker ? this.item.broker : null
                    }
                }, (err, body) => {
                    if(!err) this.brokers = body;
                });
            },
            async getLoadDetails() {
                this.modal.processing(true);
                await this.ajax('GetLoad-BrokerDetails', {
                    url: `/dispatch/loads/${this.loadId}/broker-details`,
                    method: 'GET'
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG'])
                        return;
                    }
                    this.item = body;
                });
                this.modal.processing(false);
                return;
            },
            async updateLoadDetails() {
                this.modal.processing(true);
                this.errors = {};
                await this.ajax('UpdateLoad-BrokerDetails', {
                    url: `/dispatch/loads/${this.loadId}/broker-details`,
                    method: 'PUT',
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        if(body.errors) this.errors = body.errors;
                        else this.$ShowAlert(body.message || 'Unfortunately, something went wrong.');
                        return;
                    }
                    this.modal.close();
                });
                this.modal.processing(false);
            }
        },
        async mounted() {
            await this.getLoadDetails();
            this.searchBrokers();
            this.modal.onConfirm(() => {
                this.updateLoadDetails();
            });
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}
</style>