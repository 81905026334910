<template>
    <div class="load-stops-wrapper grid gap-20">
        
        <div class="stops grid gap-20">

            

            <div class="stop grid gap-15" :key="key" v-for="(item, key) of stops">
                
                <div class="stop-header">
                    <div class="types" v-if="key > 0 && key < (stops.length - 1)">
                        <button :key="key" v-for="(stopType, key) of types" :class="[{ 'active' : stopType === item.type }]" @click="item.type = stopType">{{ stopType }}</button>
                    </div>
                    <div class="types" v-else>
                        <button class="active">{{ key == 0 ? 'Pick' : 'Drop' }}</button>
                    </div>
                    <div class="buttons">
                        <button v-if="key > 0" @click="moveUp(key, 1)"><i class="fa-solid fa-arrow-up"></i></button>
                        <button v-if="key < stops.length - 1" @click="moveDown(key, -1)"><i class="fa-solid fa-arrow-down"></i></button>
                        <button class="delete" v-if="stops.length > 2" @click="removeStop(key)"><i class="fa-solid fa-trash"></i></button>
                    </div>
                </div>
                
                <div class="stop-content grid gap-15">
                    <InputDropDownDatepicker name="Date & Time" v-model="item.date" mode="datetime" displayFormat="ddd D, MMM hh:mm A" placeholder="Select date & time" :range="true"/>
                    <StopSearch v-model="item.place"/>
                </div>
            </div>
        </div>

        <div class="extra-stop-button"><a href="" @click.prevent="addStop">Add extra stop</a></div>
        
    </div>
</template>

<script>
import StopSearch from './StopSearch.vue';
import CitySearch from './CitySearch.vue'

    export default {
        components: { StopSearch, CitySearch },
        props: ["modal", "stops"],
        data() {
            return {
                types: ['pick', 'drop']
            }
        },
        methods: {
            checkForLength() {
                if(this.stops.length < 2) {
                    this.addStop();
                }
            },
            addStop() {
                this.stops.push({
                    type: 'pick',
                    place: null,
                    date: null
                })
            },
            removeStop(key) {
                this.stops.splice(key, 1);
            },
            moveUp(key) {
                if(key <= 0) return;
                let a = JSON.parse(JSON.stringify(this.stops[key]));
                let b = JSON.parse(JSON.stringify(this.stops[key - 1]));
                this.stops[key] = b;
                this.stops[key - 1] = a;
            },
            moveDown(key) {
                 if(key >= this.stops.length - 1) return;
                let a = JSON.parse(JSON.stringify(this.stops[key]));
                let b = JSON.parse(JSON.stringify(this.stops[key + 1]));
                this.stops[key] = b;
                this.stops[key + 1] = a;
            }
        },
        mounted() {
            this.checkForLength();
        },
        watch: {
            stops: {
                deep: true,
                handler() {
                    this.checkForLength();
                }
            }
        }
}
</script>

<style lang="scss" scoped>
.load-stops-wrapper {
    padding: 20px;
}

.extra-stop-button {
    display: flex;
    justify-content: center;
}

.stop-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons {
        display: flex;
        gap: 5px;
        button {
            width: 22px;
            height: 22px;
            border: 0;
            border-radius: 7px;
            background: $themeColor1;
            box-shadow: $boxShadow;
            border: 1px solid $borderColor;
            font-size: 10px;
            padding: 0;
            cursor: pointer;
            transition: ease 0.3s;
            &.delete {
                color: $error;
            }
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.types {
    display: grid;
    grid-template-columns: 75px 75px;
    column-gap: 10px;
    button {
        border: 1px solid $borderColor;
        background: transparent;
        height: 34px;
        border-radius: 10px;
        background: $themeColor1;
        box-shadow: $boxShadow;
        font-size: 12px;
        text-transform: uppercase;
        cursor: pointer;
        transition: ease 0.3s;
        &.active {
            border: 1px solid $themeColor2;
            color: $themeColor2;
        }
    }
}

.stop {
    border-bottom: 1px solid $borderColor;
    padding-bottom: 20px;
    &:last-child {
        border: 0;
        padding: 0;
    }
    .stop-content {

    }
}
</style>